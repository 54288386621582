<template>
  <div class="container">
    <div class="search" style="display:flex">
      <el-form ref="form" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-input v-model.trim="search.kname" @change="Search" placeholder="名称"></el-input>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" size="small" @click="addModel">新建</el-button>
      </div>
    </div>
    <!-- 列表 -->
    <el-table border v-bind:data="list">
      <!-- <el-table-column label="序号" type="index" width="50"></el-table-column> -->
      <el-table-column label="名称">
        <template v-slot="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="产品code">
        <template v-slot="scope">
          <span>{{ scope.row.code }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="scope">
          <el-button type="primary" size="small" @click="editModel(scope.row)">修改</el-button>
          <el-button type="danger" size="small" @click="deleteModel(scope.row.code)">删除</el-button>
          <router-link style="margin-left:10px" :to="'/iot/promodel/model/'+ scope.row.code">
            <el-button type="info" size="small">已有型号</el-button>
          </router-link>
          <router-link style="margin-left:10px" :to="'/iot/promodel/distributemodel/'+ scope.row.code">
            <el-button type="info" size="small">分配型号</el-button>
          </router-link>
          <!-- <el-button type="info" size="small" @click="solid(scope.row)">固件包</el-button>
          <el-button type="info" size="small" @click="factory(scope.row)">生产工厂</el-button>
          <el-button type="info" size="small" @click="Product(scope.row)">指定产品</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>

    <!-- 新增修改 -->
    <el-dialog :title="title+'产品'" v-model="dialogFormVisible" width="600px">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item prop="name" label="名称" label-width="100px">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" v-if="!edit" @click="Validate(addconfirm)">新增</el-button>
          <el-button type="primary" v-if="edit" @click="Validate(editconfirm)">修改</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      search: {
        page: 1,
        limit: 10,
        kname: ''
      },
      list: [],
      total: 0,
      dialogFormVisible: false,
      edit: false,
      title: '',
      form: {
        code: '',
        name: ''
      },
      rules: {
        name: [{ required: true, message: '请输入app名称', trigger: 'blur' }],
      }
    }
  },
  computed: {},
  watch: {},
  methods: {
    handleCurrentChange(val) {
      this.search.page = val
      this.Search()
    },
    Search() {
      this.api.ProductList(this.search).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    addModel () {
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
      this.form = {}
      this.edit = false
      this.title = '新增'
      this.dialogFormVisible = true
    },
    editModel (item) {
      this.form = {}
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
      this.edit = true
      this.title = '编辑'
      this.dialogFormVisible = true
      this.api.ProductDetail({code:item.code}).then(res => {
        if (res.data.code == 200) {
          this.form=res.data.data
          return
        }
        this.$message.error('获取详情失败' + res.data.msg)
      })
    },
    deleteModel(item) {
      this.$confirm('是否删除该产品?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        this.api.ProductDelete({code: item}).then(res => {
          if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.Search()
            return
          }
          this.$message.error('删除失败' + res.data.msg)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
    solid (){

    },
    factory (){
      // this.$router.push('/iot/model/factory')
    },
    Product () {

    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    update() {
      this.api.ProductUpdate(this.form).then(res => {
        if (res.data.code == 200) {
          this.$message.success(this.title +'成功')
          this.dialogFormVisible = false
          this.Search()
          return
        }
        this.$message.error(this.title +'失败' + res.data.msg)
      })
    },
    addconfirm () {
      this.update()
    },
    editconfirm () {
      this.update()
    },
  },
  filters: {},
  mounted () {
    this.Search()
  },
  created () { }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
